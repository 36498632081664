import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";
import _defineProperty from "/Users/paitony/Works/www/mx/charge_pile/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function _createForOfIteratorHelper(o) { if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) { var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var it, normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchInnochainWithdrawalList, checkInnochainWithdrawal, fetchInnochainSettleProperty } from "@/api/finance";
import "@/utils/global.js";
import Pagination from "@/components/Pagination";
import { getToken } from "@/utils/cache";
export default {
  name: "InnochainWithdrawal",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        start_time: "",
        end_time: "",
        keyword: "",
        filter: {
          status: "",
          property_id: ''
        }
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      total_cash_amt: 0,
      total: 0,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      checkDialog: false,
      checkForm: {},
      propertyList: []
    };
  },
  created: function created() {
    var _this = this;

    fetchInnochainSettleProperty().then(function (response) {
      _this.propertyList = response.data;
    });
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this2 = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchInnochainWithdrawalList(data).then(function (response) {
        _this2.total_cash_amt = response.total_cash_amt;
        _this2.dataList = response.data;
        _this2.totalPage = response.meta.total;
        _this2.total = response.meta.total;
        _this2.tableLoading = false;
      }).catch(function (err) {
        _this2.tableLoading = false;

        _this2.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleCheck: function handleCheck(row) {
      var _this$checkForm,
          _this3 = this;

      this.checkDialog = true;
      this.checkForm = (_this$checkForm = {
        id: row.id,
        property_name: row.property_name,
        cash_order_no: row.cash_order_no,
        id_branches: row.id_branches,
        user_id: row.user_id,
        account_name: row.account_name,
        card_no: row.card_no
      }, _defineProperty(_this$checkForm, "id_branches", row.id_branches), _defineProperty(_this$checkForm, "bank_name", row.bank_name), _defineProperty(_this$checkForm, "bank_no", row.bank_no), _defineProperty(_this$checkForm, "cash_amt", row.cash_amt), _defineProperty(_this$checkForm, "status", 1), _this$checkForm);
      this.$nextTick(function () {
        _this3.$refs["checkForm"].clearValidate();
      });
    },
    handleCheckBtn: function handleCheckBtn() {
      var _this4 = this;

      this.$refs["checkForm"].validate(function (valid) {
        if (valid) {
          var data = {
            id: _this4.checkForm.id,
            status: _this4.checkForm.status
          };
          checkInnochainWithdrawal(data).then(function (res) {
            _this4.checkDialog = false;

            var _iterator = _createForOfIteratorHelper(_this4.dataList),
                _step;

            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var v = _step.value;

                if (v.id == data.id) {
                  v.status = data.status;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }

            _this4.$message({
              type: "success",
              message: '审核完成'
            });
          }).catch(function (err) {
            _this4.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleTime: function handleTime(val) {
      if (val) {
        this.searchForm["start_time"] = val[0];
        this.searchForm["end_time"] = val[1];
      } else {
        this.searchForm["start_time"] = "";
        this.searchForm["end_time"] = "";
      }
    },
    resetForm: function resetForm(formName) {
      this.searchForm["start_time"] = "";
      this.searchForm["end_time"] = "";
      this.$refs[formName].resetFields();
    }
  }
};